import { template as template_059f41184681415d875010c45e8cf4e0 } from "@ember/template-compiler";
const FKLabel = template_059f41184681415d875010c45e8cf4e0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
