import { template as template_bcd2c90299994c03a5407b3f0b8f075a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_bcd2c90299994c03a5407b3f0b8f075a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
