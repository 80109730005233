import { template as template_abcfcebbc95246b9ac42d63ef0e0f72f } from "@ember/template-compiler";
const FKControlMenuContainer = template_abcfcebbc95246b9ac42d63ef0e0f72f(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
